import { ActionTypes } from './actions';
import { getLocalStorageState } from './middleware';

export const localStorageKey = 'trivia-time';
export const initialState = (reset = false) => {
  if (localStorage.getItem(localStorageKey) && !reset)
    return getLocalStorageState();
  return {
    currentQuestion: null,
    questions: [],
    score: { correct: 0, total: 0 },
    inCorrectQuestions: [],
    correctQuestions: []
  };
};
export const quizReducer = (state = initialState(), action) => {
  switch (action.type) {
    case ActionTypes.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
        score: { correct: 0, total: action.payload.length }
      };
    case ActionTypes.QUESTION_ANSWERED:
      const isCorrect = action.payload.correct;
      const correctKey = isCorrect ? 'correctQuestions' : 'inCorrectQuestions';
      const newScore = isCorrect
        ? state.score.correct + 1
        : state.score.correct;
      return {
        ...state,
        [correctKey]: state[correctKey].concat(state.currentQuestion.key),
        score: { ...state.score, correct: newScore }
      };
    case ActionTypes.NEXT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload
      };
    case ActionTypes.RESTART_TRIVIA:
      return { ...initialState(true) };
    default:
      return state;
  }
};
