import { createSelector } from 'reselect';
export const getScore = state => state.score;

export const getCurrentQuestion = state => state.currentQuestion;

export const getQuestions = state => state.questions;

export const getCorrectQuestionKeys = state => state.correctQuestions;

export const getIncorrectQuestionKeys = state => state.inCorrectQuestions;

export const getQuestionsAndQuestionsAnswerKeys = createSelector(
  [getQuestions, getCorrectQuestionKeys, getIncorrectQuestionKeys],
  (questions, correctKeys, inCorrectKeys) => {
    return { questions, inCorrectKeys, correctKeys };
  }
);

export const getQuestionsAndCurrentQuestion = createSelector(
  [getQuestions, getCurrentQuestion],
  (questions, currentQuestion) => ({ questions, currentQuestion })
);
