import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-materialize';
const propTypes = {
  label: PropTypes.string
};

function PlayAgainButton({ label, ...rest }) {
  return (
    <Button className='play-again' {...rest}>
      {label}
    </Button>
  );
}

PlayAgainButton.propTypes = propTypes;

export default PlayAgainButton;
