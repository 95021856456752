import React, { createContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useFetchLabels } from './hooks';
import { StartScreen, QuizScreen, ResultsScreen } from 'components/';

import './App.css';

export const LabelsContext = createContext({});
function App() {
  const labels = useFetchLabels();

  return (
    <LabelsContext.Provider value={labels}>
      <Switch>
        <Route exact path='/' component={StartScreen} />
        <Route exact path='/quiz' component={QuizScreen} />
        <Route exact path='/results' component={ResultsScreen} />
      </Switch>
    </LabelsContext.Provider>
  );
}

export default App;
