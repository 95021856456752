import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-materialize';
import cx from 'classnames';
const propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string),
  isMultipleChoice: PropTypes.bool,
  onAnswer: PropTypes.func
};

function AnswerButtons(props) {
  return (
    <div className={cx('answers', { multiple: props.isMultipleChoice })}>
      {props.answers.map(answer => (
        <Button
          key={answer}
          onClick={props.onAnswer(answer)}
          className='answer'
        >
          {answer}
        </Button>
      ))}
    </div>
  );
}

AnswerButtons.propTypes = propTypes;

export default AnswerButtons;
