import { useState, useEffect } from 'react';

/**
 * Fetches the labels, keeps them in state
 * @return labels
 */
export const useFetchLabels = () => {
  const [labels, setLabels] = useState({});
  useEffect(() => {
    fetch('./labels.json')
      .then(res => res.json())
      .then(labelsJson => {
        setLabels(labelsJson);
      });
  }, []);

  return labels;
};
