import React, { useState, useEffect, useContext } from 'react';

import { LabelsContext } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getQuestionsAndCurrentQuestion } from 'store/selectors';
import { getAnswerListForQuestion, formatLabel } from 'utils';
import { questionAnswered, nextQuestion } from 'store/actions';
import QuestionCard from './QuestionCard';
import AnswerButtons from './AnswerButtons';

function QuizScreen(props) {
  const { currentQuestion, questions } = useSelector(
    getQuestionsAndCurrentQuestion
  );
  const labels = useContext(LabelsContext);
  const dispatch = useDispatch(LabelsContext);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const answersList = getAnswerListForQuestion(currentQuestion);
    setAnswers(answersList);
  }, [currentQuestion]);

  const answerQuestion = answer => () => {
    const correct = answer === currentQuestion.correctAnswer;
    dispatch(questionAnswered({ correct }));

    const nextQuestionKey = currentQuestion.key + 1;

    if (nextQuestionKey < questions.length) {
      dispatch(nextQuestion(questions[nextQuestionKey]));
    } else {
      dispatch(nextQuestion(null));
      props.history.push('/results');
    }
  };

  if (!currentQuestion) {
    return <Redirect to={'/'} />;
  }
  return (
    <div className='quiz-screen'>
      <h4>{currentQuestion.category}</h4>
      <QuestionCard question={currentQuestion.question} />
      <div className='current-count'>
        {formatLabel(
          labels.countFormat,
          !!currentQuestion ? currentQuestion.key + 1 : 0,
          questions.length
        )}
      </div>
      <AnswerButtons
        answers={answers}
        isMultipleChoice={currentQuestion.type === 'multiple'}
        onAnswer={answerQuestion}
      />
    </div>
  );
}

export default QuizScreen;
