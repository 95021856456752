import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LabelsContext } from 'App';
import { formatLabel, fetchQuestions } from 'utils';
import { nextQuestion, setQuestions } from 'store/actions';
import { Button, Icon } from 'react-materialize';
function StartScreen(props) {
  const labels = useContext(LabelsContext);
  const dispatch = useDispatch();
  const [loadingQuestions, setLoading] = useState(false);
  const onBegin = () => {
    setLoading(true);
    fetchQuestions().then(questions => {
      dispatch(setQuestions(questions));
      dispatch(nextQuestion(questions[0]));
      setLoading(false);
      props.history.push('/quiz');
    });
  };
  return (
    <div className='start-screen'>
      <h3>{labels.welcome}</h3>
      <p>{formatLabel(labels.prompt, 10)}</p>
      <p>{labels.misc} </p>
      <Button
        className='begin-button'
        onClick={onBegin}
        disabled={loadingQuestions}
      >
        {loadingQuestions ? <Icon>cloud</Icon> : labels.beginButton}
      </Button>
    </div>
  );
}

export default StartScreen;
