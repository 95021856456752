import PropTypes from 'prop-types';

export const Question = {
  key: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  difficulty: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  correctAnswer: PropTypes.string.isRequired,
  incorrectAnswers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const Score = {
  correct: PropTypes.number.isRequired,
  inCorrect: PropTypes.number.isRequired
};
