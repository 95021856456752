export const ActionTypes = {
  SET_QUESTIONS: 'SET_QUESTIONS',
  QUESTION_ANSWERED: 'QUESTION_ANSWERED',
  NEXT_QUESTION: 'NEXT_QUESTION',
  RESTART_TRIVIA: 'RESTART_TRIVIA'
};

export const setQuestions = payload => ({
  type: ActionTypes.SET_QUESTIONS,
  payload
});

export const questionAnswered = payload => ({
  type: ActionTypes.QUESTION_ANSWERED,
  payload
});

export const nextQuestion = payload => ({
  type: ActionTypes.NEXT_QUESTION,
  payload
});

export const restartTrivia = _ => ({
  type: ActionTypes.RESTART_TRIVIA
});
