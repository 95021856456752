import { decode } from 'he';

export const formatLabel = (label = '', ...args) => {
  if (typeof label !== 'string')
    throw new Error(
      `label must be of type string to format. ${label} is not a string.`
    );
  return args.reduce((newLabel, cur, index) => {
    const t = newLabel.replace(`{${index}}`, cur);
    return t;
  }, label);
};

export const fetchQuestions = () => {
  const questionCount = process.env.REACT_APP_NUMBER_OF_QUESTIONS || 10;
  const questionType = process.env.REACT_APP_QUESTION_TYPE || 'boolean';

  return fetch(
    `https://opentdb.com/api.php?amount=${questionCount}&difficulty=hard&type=${questionType}`
  )
    .then(res => res.json())
    .then(questionJson => questionJson.results)
    .then(questions => transformApiQuestions(questions));
};
export const transformApiQuestions = questions => {
  return questions.map((question, key) => {
    const correctAnswer = question.correct_answer;
    const incorrectAnswers = question.incorrect_answers;
    return {
      key,
      category: question.category,
      type: question.type,
      difficulty: question.difficulty,
      question: decode(question.question),
      correctAnswer,
      incorrectAnswers
    };
  });
};

/**
 * Combines correct and incorrect answers.
 * Shuffles for multiple choice questions and make them true/false for boolean questions.
 * @param {Question} question
 * @return {[string]} answerList
 */
export const getAnswerListForQuestion = question => {
  if (!question) return [];
  const allAnswers = [question.correctAnswer, ...question.incorrectAnswers];
  if (question.type === 'boolean') {
    return allAnswers.sort().reverse();
  } else {
    return shuffle(allAnswers);
  }
};

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
