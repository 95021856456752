import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-materialize';

import { Question } from 'components/models';
import { LabelsContext } from 'App';

const propTypes = {
  isCorrect: PropTypes.bool,
  question: PropTypes.shape(Question).isRequired
};

function QuestionResult(props) {
  const labels = useContext(LabelsContext);
  const correctIcon = !!labels.correctIcon ? labels.correctIcon : 'add';
  const incorrectIcon = !!labels.incorrectIcon ? labels.incorrectIcon : 'clear';
  const icon = props.isCorrect ? correctIcon : incorrectIcon;
  const colorText = props.isCorrect ? 'green-text' : 'red-text';

  return (
    <div className='question-result'>
      <Icon className={colorText}>{icon}</Icon>
      <p>{props.question.question}</p>
    </div>
  );
}

QuestionResult.propTypes = propTypes;

export default QuestionResult;
