import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LabelsContext } from 'App';
import { getScore, getQuestionsAndQuestionsAnswerKeys } from 'store/selectors';
import QuestionResult from './QuestionResult';
import PlayAgainButton from './PlayAgainButton';
import { restartTrivia } from 'store/actions';

function ResultsScreen(props) {
  const labels = useContext(LabelsContext);
  const dispatch = useDispatch();
  const { questions, inCorrectKeys, correctKeys } = useSelector(
    getQuestionsAndQuestionsAnswerKeys
  );
  const score = useSelector(getScore);

  const isQuestionCorrect = question => {
    if (correctKeys.includes(question.key)) return true;
    if (inCorrectKeys.includes(question.key)) return false;
  };

  const playAgain = () => {
    dispatch(restartTrivia());
    props.history.push('/');
  };
  if (questions.length === 0) {
    return <Redirect to={'/'} />;
  }
  return (
    <div className='results-screen'>
      <h4>
        {labels.scoredLabel} <br /> {score.correct}/{score.total}
      </h4>
      <div className='questions'>
        {questions.map(q => (
          <QuestionResult
            key={q.key}
            isCorrect={isQuestionCorrect(q)}
            question={q}
          />
        ))}
      </div>
      <PlayAgainButton onClick={playAgain} label={labels.playAgain} />
    </div>
  );
}

export default ResultsScreen;
