import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-materialize';

const propTypes = {
  question: PropTypes.string.isRequired
};

function QuestionCard(props) {
  return (
    <div className='question'>
      <Card>
        <p>{props.question}</p>
      </Card>
    </div>
  );
}

QuestionCard.propTypes = propTypes;

export default QuestionCard;
