import { restartTrivia } from './actions';
import { localStorageKey } from './reducer';

/**
 * This middle ware is used to store the state of the application in localstorage.
 *
 */
export const localStorageMiddleware = store => next => action => {
  next(action);

  saveToLocalStorage(store.getState());
  if (action.type === restartTrivia().type) {
    removeAppLocalStorage();
  }
};

/**
 * Used to save the store application state in localStorage
 * @param {object} state
 */
const saveToLocalStorage = state => {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
};
/**
 * Removes application state in localStorage
 * @param {object} state
 */
const removeAppLocalStorage = () => {
  localStorage.removeItem(localStorageKey);
};

export const getLocalStorageState = () => {
  return JSON.parse(localStorage.getItem(localStorageKey));
};
